<template>
  <vx-card
    :title="lang.surveyIntegrations.channels.whatsapp.settings.expiration.title[
        languageSelected
      ]"
    class="overflow-hidden mb-2"
    :subtitle="lang.surveyIntegrations.channels.whatsapp.settings.expiration.subtitle[
        languageSelected
      ]"
  >
    <div class="vx-row mb-4">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <span class="custom-label">
          {{
              lang.surveyIntegrations.channels.whatsapp.settings.expiration
                .form.switch[languageSelected]
          }}
        </span>
        <vx-slider color="danger" v-model="activeN" />
      </vs-col>
    </div>
    <div
      class="vx-row mb-base" v-show="activeN"
    >
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'" class="pl-0">
        <form-group
                class="settings-form-group"
                :label="lang.surveyIntegrations.channels.whatsapp.settings.expiration
                .form.time.label[languageSelected]"
                :tooltip="lang.surveyIntegrations.channels.whatsapp.settings.expiration
                .form.time.tooltip[languageSelected]"
        >
          <TimeLog v-model="expirationTimeN" />
        </form-group>
      </vs-col>
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
                class="inputx w-full mt-2"
                :label="lang.surveyIntegrations.channels.whatsapp.settings.expiration
                .form.message.label[languageSelected]"
                :placeholder="lang.surveyIntegrations.channels.whatsapp.settings.expiration
                .form.message.placeholder[languageSelected]"
                v-model="expirationMessageN"
        />
      </vs-col>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Expiration',
  components: {
    FormGroup: () => import('@/views/home/bot-maker/add-bot/Components/FormGroup'),
    TimeLog: () => import('@/components/TimeLog.vue')
  },
  props: {
    expirationActive: Boolean,
    expirationTime: Number,
    expirationMessage: String,

  },
  data() {
    return {
      activeN: this.expirationActive,
      expirationTimeN: this.expirationTime,
      expirationMessageN: this.expirationMessage,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {},
  watch: {
    activeN(newVal) {
      this.$emit('update:expirationActive', newVal)
    },
    expirationTimeN(newVal) {
      this.$emit('update:expirationTime', newVal)
    },
    expirationMessageN(newVal) {
      this.$emit('update:expirationMessage', newVal)
    },
  }
}
</script>

<style lang="scss" scoped>
  .settings-form-group {
    display: flex;
    align-items: center;
  }
</style>
