var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration.title[
      _vm.languageSelected
    ],"subtitle":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration.subtitle[
      _vm.languageSelected
    ]}},[_c('div',{staticClass:"vx-row mb-4"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('span',{staticClass:"custom-label"},[_vm._v("\n        "+_vm._s(_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration
              .form.switch[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vx-slider',{attrs:{"color":"danger"},model:{value:(_vm.activeN),callback:function ($$v) {_vm.activeN=$$v},expression:"activeN"}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeN),expression:"activeN"}],staticClass:"vx-row mb-base"},[_c('vs-col',{staticClass:"pl-0",attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('form-group',{staticClass:"settings-form-group",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration
              .form.time.label[_vm.languageSelected],"tooltip":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration
              .form.time.tooltip[_vm.languageSelected]}},[_c('TimeLog',{model:{value:(_vm.expirationTimeN),callback:function ($$v) {_vm.expirationTimeN=$$v},expression:"expirationTimeN"}})],1)],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-2",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration
              .form.message.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.expiration
              .form.message.placeholder[_vm.languageSelected]},model:{value:(_vm.expirationMessageN),callback:function ($$v) {_vm.expirationMessageN=$$v},expression:"expirationMessageN"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }